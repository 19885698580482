.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  white-space: nowrap;
  font-size:250%;
  justify-content: center;  
}

.mainflex {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
}

#nav-links {
  margin: auto;
  align-items: center;
  justify-content: center;
}

ul {
  display: inline-flex;
  padding-inline-start: 0;
  align-items: center;
  justify-content: center;
}

ul li {
  display: inline-flex;
  padding: 15px;
}

img:hover {
  cursor: pointer;
}

html {
  margin: 0;
  padding: 0;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

#particles canvas{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}